.container {
    text-align: center;
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;;
}

.buttonStyle {
    margin: 5px !important;
    border: none;
    border-radius: 3px !important;
    width: 10vw;
    height: 3rem;
}

.buttonStyleRoom {
    margin: 5px !important;
    border: none;
    border-radius: 3px !important;
    width: 6vw;
    height: 3rem;
}

.inputFields {
    border-radius: 3px !important;
}