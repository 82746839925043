.chatContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 60vw;
    height: calc(85vh - 4rem);
    margin: 2rem auto;
    overflow-y: scroll;
    padding: 0 2rem;
}

.buttonGroup {
    position: fixed;
    top: 0;
    left: 0;
    margin: 10px;
}

.searchContainer {
    display: flex;
    align-items: center;
    width: 60vw;
    margin: 1rem auto;
    justify-content: center;
}

.searchInput {
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
}

.searchButton {
    margin: 10px;
    height: 3rem;
}

.messageList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: calc(60vw - 4rem);
}

.messageItem {
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.editTextInput {
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
}

.saveButton {
    margin: 1%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.chatInput {
    padding: 10px;
    border-radius: 4px;
    width: 85%;
}

.chatInputButton {
    margin: 2%;
    padding: 10px 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 15%;
    height: 3rem;
}

.bottomFixed {
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.reactions {
    display: flex;
    align-items:center;
    margin: 1rem 0;
}

.reaction {
    margin: 0 1rem;
    letter-spacing: 1rem;
}

.emoji {
    display: flex;
    align-items: center;
    background-color: rgb(205 229 229);
    border-radius: 20px;
    margin: 0 0.5rem;
}

.emo {
    margin: 0.3rem 0.3rem;
}
