.home {
    background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.365)) , url(/public/Home.jpg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.btn {
    background-color: #4cce51; /* Green */
    border: none;
    font-weight: 600;
    border-radius: 40px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    font-size: 2rem;
    width: 15rem;
    height: 5rem;
    margin: 2rem;
}

.btn:hover {
    background-color: #4fdc54;
}
